<template>
	<form id="revinate_contact_api_form" :token="tokens[locale] ?? tokens['en']" @submit.prevent="submit()">
		<div class="newsletter-wrapper">
			<input id="firstname" type="text" :placeholder="$t('formFirstName')" name="firstName" />
			<input id="lastname" type="text" :placeholder="$t('formLastName')" name="lastName" />
			<input id="emailaddress" class="long" :placeholder="$t('formEmail')" type="email" name="email" required />
			<button type="submit" class="button">{{ button }}</button>
		</div>
	</form>
</template>

<script setup>
defineProps({
	button: { type: String, default: '' },
});

const { locale } = useI18n();

useHead({
	script: [
		{
			type: 'text/javascript',
			src: 'https://contact-api.inguest.com/bundles/revinatecontactapi/js/revinate-form.js?v=1',
		},
	],
});

const tokens = {
	nl: '5bf69779-b62b-4d9b-abf7-41d1c1767420',
	en: '3039438c-3b39-4f8d-9b6e-2c56a0022327',
};

const submit = async () => {
	window.revFormOnSubmit();
};
</script>

<style lang="scss" scoped>
/* stylelint-disable-next-line selector-id-pattern */
#revinate_contact_api_form {
	margin: 35px 0;
}

.newsletter-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: 15px;

	input {
		border-radius: 0;
	}
}

:deep(input:not([type='checkbox'], [type='radio'], [type='submit'])),
select,
textarea {
	color: #555;
	width: 100%;
	max-width: 500px;
	padding: 20px;
	border: 1px solid #efefef;
	font-size: 13px;
	font-family: $body-font-family;
}

:deep(input:not([disabled], [type='submit'])),
select,
textarea {
	background-color: $primary-background-color;
}

select {
	background: url('~/assets/images/icon-form-dropdown.png') no-repeat $primary-background-color;
	background-position: right 15px top 50%;
	appearance: none;
}

input[type='submit'] {
	width: auto;
}

textarea {
	min-height: 140px;
	max-width: 100%;
}
</style>
